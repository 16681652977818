<template>
  <a-modal
    title="新建"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="用户" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            showSearch
            v-decorator="['user_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            placeholder="请输入用户名"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="user_fetching ? undefined : null"
            allow-clear
            @search="this.userSearch"
            @change="handleUserChange"
          >
            <a-spin v-if="user_fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in userList" :key="d.id" :value="d.id">
              {{ d.nickname }} <span v-if="d.cellphone">({{ d.cellphone }})</span>
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import debounce from 'lodash/debounce'
import { user_list } from '@/api/applet_shop_user'
export default {
  name: 'CreateCouponGoodsForm',
  components: {},
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      user_fetching: false,
      selectUser: undefined,
      userList: []
    }
  },
  created () {
    this.handleUserSearch()
  },
  methods: {
    handleUserChange (value) {
      this.selectGoods = undefined
      if (value === undefined) {
        this.handleUserSearch(undefined)
      } else {
        this.selectUser = this.userList.find(item => value === item.id)
      }
    },
    handleUserSearch (value) {
      this.userList = []
      this.user_fetching = true
      user_list({ nickname: value }).then(({ data }) => {
        const result = data.entries || []
        this.userList = result
      }).finally(() => {
        this.user_fetching = false
      })
    },
    userSearch: debounce(function (v) {
      this.handleUserSearch(v)
    }, 800)
  }
}
</script>
<style>
</style>
